import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import FooterAlt from "../components/footer-alt"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404 Not Found" />
    <h1 style={{ paddingTop: 80 }}>Page not found</h1>
    <p><Link to="/">Click here</Link> to return to the main page</p>

    <FooterAlt />
  </Layout>
)

export default NotFoundPage
